import {Stack} from 'react-bootstrap';
import agentImg from 'assets/img/agent.png';
import Button from 'components/base/Button';
import LandingNavbar from "./LandingNavbar";
import Footer from "./Footer";

const Agents = () => {
    return (
        <div className="bg-white">
            <LandingNavbar/>

            <div className="container-small px-lg-7 px-xxl-3">
                <div className="position-relative z-index-2">
                    <div
                        className="align-items-center justify-content-between text-center text-lg-start mb-6 mb-lg-2 row">
                        <div className="order-lg-1 col-lg-5">
                            <img
                                src={agentImg}
                                width={470}
                                className="img-fluid mb-7"
                                alt="Agents"
                            />

                        </div>
                        <div className="col-lg-6">
                            <h1 className="fw-bolder mb-3">Are you a literary agent?</h1>
                            <p
                            className="mb-4 px-md-7 px-lg-0">
                            Please email me your updated manuscript wishlist, detailing your current preferences and specific
                        areas of interest.
                        This will enable the tool to more accurately match you with manuscripts that align closely with your
                        tastes and professional goals.</p>
                    <a className="btn btn-primary" href="mailto:yavar@manuscriptmingle.com">Contact</a>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
};

export default Agents;
