import Footer from 'Footer';
import useSettingsMountEffect from 'hooks/useSettingsMountEffect';
import LandingNavbar from 'LandingNavbar';
import {useState} from 'react';
import AgentSearch from "./AgentSearch";
import AgentResults from "./AgentResults";

const Authors = () => {
    useSettingsMountEffect({
        disableNavigationType: true,
        disableHorizontalNavbarAppearance: true,
        disableVerticalNavbarAppearance: true,
        disableHorizontalNavbarShape: true
    });

    const [searchQuery, setSearchQuery] = useState('');
    const [results, setResults] = useState<any[]>([]);
    const [compState, setCompState] = useState("search");

    const handleSearch = async () => {
        setCompState("loading");
        try {
            const response = await fetch('https://man-ming-backend-ejdbs5hqiq-wl.a.run.app/query', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({query_body: searchQuery}),
            });
            const data = await response.json();
            const agents = data['results']
            setResults(agents);
            console.log(results)
            setCompState("results")
        } catch (error) {
            console.error('There was an error!', error);
            setCompState("search");
        }
    };


    return (
        <div className="bg-white">
            <LandingNavbar/>
            <div className='content py-5'>
                <h2 className="mb-5">For Authors</h2>
                <div className='authors'>
                    {compState === "loading" && <div>Working...</div>}
                    {compState === "results" && <AgentResults results={results}/>}
                    {compState === "search" &&
                        <AgentSearch
                            searchQuery={searchQuery}
                            setSearchQuery={setSearchQuery}
                            handleSearch={handleSearch}
                        />
                    }
                </div>
                <Footer />
            </div>
        </div>
    );
};

export default Authors;


// Search component



