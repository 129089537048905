import {ProgressBar, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faTwitter } from "@fortawesome/free-brands-svg-icons"
import {faCircleCheck, faCircleXmark, faEnvelope, faLink, faCircleQuestion} from "@fortawesome/free-solid-svg-icons";

const getProgressColorVariant = (value: number) => {
  if (value < 21) {
    return 'warning';
  } else if (value < 41) {
    return 'info';
  } else if (value < 70) {
    return 'primary';
  } else {
    return 'success';
  }
};

const getProbability = (value: number) => {
    return Math.round(value * 10000) / 100;
}
const AgentResults: React.FC<{ results: any[] }> = ({results}) => {
    console.log(results)
    return (
        <div className="mb-6">
            {/*<AdvanceTableProvider {...table}>*/}
            <h3 className="mb-4">Top {results.length} agents for your query</h3>
            <div className="scrollbar ms-n1 ps-1">
                <Table className="phoenix-table fs-9">
                    <thead>
                    <tr>
                        <th className="ps-0 pe-5 border-end">
                            Name
                        </th>
                        <th className="ps-4 pe-5 border-end" style={{width: '25%'}}>
                            Summary
                        </th>
                        <th className="ps-4 pe-5 border-end">
                            Age Groups
                        </th>
                        <th className="ps-4 pe-5 border-end">
                            Top Genres
                        </th>
                        <th className="ps-4 pe-5 border-end">
                            External Links
                        </th>
                        {/*<th className="ps-4 pe-5 border-end">*/}
                        {/*    Accepts Submissions*/}
                        {/*</th>*/}
                        <th className="ps-4 pe-5 border-end">
                            Match Score
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {results.map((result, index) => (
                        <tr className="hover-actions-trigger btn-reveal-trigger">
                            <td className="white-space-nowrap fw-semi-bold ps-4 border-end">
                                {result.name}
                            </td>
                            <td className="fw-semi-bold ps-4 border-end">
                                {result.agent_summary}
                            </td>
                            <td className="fw-semi-bold ps-4 border-end">
                                {result.age_groups.map((ag: string, index: number) => (
                                    <h4>
                                        <span className="me-2 badge badge-pill bg-info">{ag}</span>
                                    </h4>
                                ))}
                            </td>
                            <td className="fw-semi-bold ps-4 border-end">
                                {
                                    result["top_five_genres"].map((genre: string, index: number) => (
                                    <h5>
                                        <span className="me-2 badge badge-pill bg-dark">{genre}</span>
                                    </h5>
                                ))}

                                {}
                            </td>
                            <td className="white-space-nowrap fw-semi-bold ps-4 border-end">
                                {result.mswl_url && result.mswl_url !== "unknown" && 
                                    <a href={result.mswl_url}>
                                        <span className="me-2 badge badge-pill bg-primary">MSWL</span>
                                    </a>
                                }
                                {result.twitter && result.twitter !== "unknown" &&
                                    <a href={"https://www.twitter.com/" + result.twitter} target="_blank">
                                        <FontAwesomeIcon icon={faTwitter} className="me-2" size="2xl"/>
                                    </a>
                                }
                                {result.email && result.email !== "unknown" &&
                                    <a href={"mailto:" + result.email}>
                                        <FontAwesomeIcon icon={faEnvelope} className="me-2" size="2xl"/>
                                    </a>
                                }
                                {result.website && result.website !== "unknown" &&
                                    <a href={result.website}>
                                        <FontAwesomeIcon icon={faLink} className="me=2" size="2xl"/>
                                    </a>
                                }

                            </td>
                            {/*<td className="white-space-nowrap fw-semi-bold ps-4 border-end">*/}
                            {/*    {result.accepts_submissions === 'true'  &&*/}
                            {/*        <FontAwesomeIcon icon={faCircleCheck} className="text-success" size="2x"/>*/}
                            {/*    }*/}
                            {/*    {result.accepts_submissions === 'false'  &&*/}
                            {/*        <FontAwesomeIcon icon={faCircleXmark} className="text-danger" size="2x"/>*/}
                            {/*    }*/}
                            {/*    {result.accepts_submissions !== 'true'  && result.accepts_submissions !== 'false'  &&*/}
                            {/*        <FontAwesomeIcon icon={faCircleQuestion} className="text-danger" size="2x"/>*/}
                            {/*    }*/}
                            {/*</td>*/}
                            <td className="white-space-nowrap fw-semi-bold ps-4 border-end">
                                {/*{result.match_score}*/}

                                <p className="text-800 fs-10 mb-0">{getProbability(result.match_score)}%</p>
                                  <ProgressBar
                                    now={getProbability(result.match_score)}
                                    style={{ height: 3 }}
                                    variant={getProgressColorVariant(getProbability(result.match_score))}
                                    className="bg-primary-100"
                                   />
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </div>
        </div>
    );
};

export default AgentResults;