import Logo from 'components/common/Logo';
import { Modal, Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import SearchBox from 'components/common/SearchBox';
import Button from 'components/base/Button';
import ThemeToggler from 'components/common/ThemeToggler';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import DropdownSearchBox from 'components/common/DropdownSearchBox';
import SearchResult from 'components/common/SearchResult';


const LandingNavbar = () => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [openSearchModal, setOpenSearchModal] = useState(false);

  useEffect(() => {
    const toggleShadowClass = () => {
      if (window.scrollY > 300) {
        containerRef.current?.classList.add('navbar-shadow');
      } else {
        containerRef.current?.classList.remove('navbar-shadow');
      }
    };

    document.addEventListener('scroll', () => toggleShadowClass());

    return () => document.removeEventListener('scroll', toggleShadowClass);
  }, []);

  return (
    <>
      <div className="bg-white sticky-top landing-navbar" ref={containerRef}>
        <Navbar className="px-0 px-lg-0 px-xxl-0 container-small mx-0" expand="lg">
          <Navbar.Brand
            as={Link}
            to="/"
            className="text-decoration-none flex-1 flex-lg-grow-0"
          >
            <Logo />
          </Navbar.Brand>
          <ThemeToggler className="mx-2 d-lg-none" />
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse>
            <Nav className="me-auto mb-2 mb-lg-0" as="ul">
              <li className="border-bottom border-bottom-lg-0 nav-item">
                <Link to="/" data-rr-ui-event-key="/" className="lh-1 py-0 fs-9 fw-bold py-3 nav-link">
                  Home
                </Link>
              </li>
              <li className="border-bottom border-bottom-lg-0 nav-item">
                <Link to="/authors" data-rr-ui-event-key="/" className="lh-1 py-0 fs-9 fw-bold py-3 nav-link">
                  For Authors
                </Link>
              </li>
              <li className="border-bottom border-bottom-lg-0 nav-item">
                <Link to="/agents" data-rr-ui-event-key="/" className="lh-1 py-0 fs-9 fw-bold py-3 nav-link">
                  For Agents
                </Link>
              </li>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </>
  );
};

export default LandingNavbar;
