import { RouteObject, createBrowserRouter } from 'react-router-dom';
import Error404 from 'pages/error/Error404';
import App from 'App';
import { lazy } from 'react';
import Home from 'Home';
import Authors from 'Authors';
import Agents from "./Agents";

const FontAwesomeExample = lazy(
  () => import('pages/modules/components/FontAwesomeExample')
);

const FeatherIconsExample = lazy(
  () => import('pages/modules/components/FeatherIconsExample')
);

const UniconsExample = lazy(
  () => import('pages/modules/components/UniconsExample')
);

const routes: RouteObject[] = [
  {
    element: <App />,
    children: [
      {
        path: '/authors',
        element: <Authors />
      },
      {
        path: '/agents',
        element: <Agents />
      },
      {
        path: '/',
        element: <Home />
      },
      {
        path: '*',
        element: <Error404 />
      }
    ]
  }
];

export const router = createBrowserRouter(routes);

export default routes;
