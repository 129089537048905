import {Card} from 'react-bootstrap';
import {Col, Row} from 'react-bootstrap';

const AgentSearch: React.FC<{ searchQuery: string; setSearchQuery: (query: string) => void; handleSearch: () => void }> = (
    {
          searchQuery,
          setSearchQuery,
          handleSearch
      }) => {

    const yavar_query = "It's been said that God doesn't play dice with the universe. However, for Ed, an alcoholic insurance auditor, there's growing evidence that some gods might indeed be flinging dice at the unsuspecting mortals below. How else to explain the sudden surge of bizarre, laughably improbable accidents? Ed, content in his asexuality, shares his home with Shahrzad, an Iranian-Canadian mathematician unapologetically exploring her sexual freedoms. Then there's Hannah, a devout yet ever-cheerful MI5 agent. The three reluctant protagonists must unravel the connection between a mysterious young girl and the inexplicable surge of astronomically unlikely events shaking the globe. After all, a meteor wiping life from Earth isn't at all impossible--it's just incredibly improbable.\n" +
        "\n" +
        "“THE IMPROBABILITY AUDITS” is not a work of science fiction, though there's plenty of fiction about science in it. Beyond that, it's a dark comedy, a tale of misfits wrestling with life's awkward questions on mortality, sexuality, religion, and whatever it is we all eventually scrape together and call family.\n" +
        "\n" +
        "The above is the “back cover” of my manuscript, THE IMPROBABILITY AUDITS. Completed at slightly over eighty-six thousand words, the book combines elements of science, mystery, humor, and a touch of magic. Imagine an alternate universe where, while Pratchett and Gaiman were working on Good Omens, Douglas Adams–in more of a Dirk Gently mood than Hitchhiker's Guide one–popped in for a short visit. \n";

    const wendiferously_query = "Eunice's whole life is organized around three simple rules. 1. Always refer to Cinderella as family. 2. Keep Cinderella from touching rats or mice. 3. Never look upon Cinderella between the hours of midnight and three am.\n" +
        "\n" +
        "Eunice’s childhood passes in a blur of nightmares, tears, and near-misses with the monster living in the cellar. Throughout it all, her commitment to the rules never wavers. But when Eunice strikes up an unexpected friendship with the handsome Prince Credence, she begins to chafe against the limitations of devoting her whole life to containing Cinderella.\n" +
        "\n" +
        "Her younger sister Hortense steps up to try and relieve some of the pressure in containing Cinderella, but Hortense is not as practiced at following the rules as Eunice. Cinderella breaks free. Her eldritch powers transform rats into ball gowns and maggots into footmen. Cinderella attends the ball and sweeps Prince Credence off his feet, leaving behind a trail of carnage and a single green glass slipper.\n" +
        "\n" +
        "Faced with the threat of Cinderella unleashed and a gullible Prince who seems likely to make Cinderella his queen, Eunice must decide how much more of herself she’s willing to sacrifice to stop Cinderella, and whether it's worth the loss of her own life and limb to do so.\n" +
        "\n" +
        "Cosmic horror meets Cinderella in We Kept Her in the Cellar, a horror fairy tale retelling complete at 70,000 words. It will appeal to fans of Malice by Heather Walter, Wendy, Darling by A.C. Wise, and Spinning Silver by Naomi Novik.";



    return (
        <div className='query-search'>
            <Row className="gx-lg-4 gx-xl-6 gx-3 py-4 z-index-2 position-sticky bg-soft email-header">

                <Col className="col">
                    <Card>
                        <Card.Body>
                            <p>
                                If you're on the hunt for a literary agent, you probably have a query letter ready for
                                your manuscript. To find agents who are the best fit for your work, simply input
                                your query letter in the space provided below.
                            </p>
                            <p>
                                Don’t have a query letter yet? No problem! Instead, you can write a summary that
                                includes all or some of the following key elements:

                                <ul>
                                    <li><span className="fw-bold">Title and Genre</span>Specify the title of your book
                                        and its genre.
                                    </li>
                                    <li><span className="fw-bold">Synopsis/Summary</span>: Provide a concise summary of
                                        your book, focusing on the plot, main  characters, and the core conflict or
                                        theme, but without revealing the entire storyline.
                                    </li>
                                    <li><span className="fw-bold">Target Audience and Market Analysis</span>: Briefly
                                        describe the intended audience for your book and how it fits into the current
                                        market. You might compare it to similar titles or explain how it fills a gap in
                                        the market.
                                    </li>
                                    <li><span className="fw-bold">Bio</span>: Share relevant information about yourself.
                                        This could include your writing background, any previously published work,
                                        relevant professional experience, or personal details that connect to the
                                        content of your book.
                                    </li>
                                    <li><span className="fw-bold">Comparative Titles</span>:  List a few books similar to yours to help identify the market niche
                                it belongs to.
                                    </li>
                                </ul>
                            </p>
                            <p>
                                Remember, your text is processed by our tool, not a human reader. The information you
                                provide will be used to find the best-matching agent based on the publicly available
                                information about them.
                            </p>

                            <p>
                                Still unsure what to put in? You can try the &nbsp;
                                <a href="" onClick={(e) => {
                                    setSearchQuery(yavar_query);
                                    e.preventDefault()
                                }}>query</a> for my own
                                 &nbsp; <a href="https://yavar.io/#book" target="_blank">book</a> &nbsp;
                                or try this wonderful  &nbsp;
                                <a href="" onClick={(e) => {
                                    setSearchQuery(wendiferously_query);
                                    e.preventDefault()
                                }}>query</a>
                                 &nbsp; from reddit's &nbsp;
                                <a target="_blank" href="https://www.reddit.com/r/PubTips/comments/wswja9/comment/ilnfwxo/">
                                [PubTip] Agented Authors: Post successful queries
                                </a>.
                            </p>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>


            <Row className='gx-lg-4 gx-xl-6 gx-3 py-4 z-index-2 position-sticky bg-soft'>
                <Col className="col">
                    <Card>
                        <Card.Body>
                            <Row className="g-3 mb-2">
                                <form>
                                    <Col xs={12}>
                                        <label>Manuscript Query </label>
                                        <textarea
                                            className='form-control'
                                            rows={30}
                                            value={searchQuery}
                                            onChange={(e) => setSearchQuery(e.target.value)}
                                            maxLength={4000}
                                            style={{fontSize: 16 }}
                                        />
                                        <div>Character count: {searchQuery.length} / 4000</div>
                                    </Col>
                                </form>
                            </Row>
                            <Row>
                                <Col>
                                    <button className='btn btn-primary' onClick={handleSearch}>Find Agents</button>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>

                </Col>
            </Row>

        </div>
    );
};

export default AgentSearch;