import Footer from 'Footer';
import HeroHeader from 'HeroHeader';
import useSettingsMountEffect from 'hooks/useSettingsMountEffect';
import LandingNavbar from 'LandingNavbar';

const Home = () => {
  useSettingsMountEffect({
    disableNavigationType: true,
    disableHorizontalNavbarAppearance: true,
    disableVerticalNavbarAppearance: true,
    disableHorizontalNavbarShape: true
  });

  return (
    <div className="bg-white">
      <LandingNavbar />
      <HeroHeader />
      <Footer className='position-absolute'/>
    </div>
  );
};

export default Home;
